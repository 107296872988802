import React, {useState, useCallback} from 'react';
import { LegacyStack, ChoiceList, Text } from "@shopify/polaris";
import './offerTypeCheckbox.css';

const OfferTypeCheckbox = ({offerType, changeOfferType, shopBrainType}) => {
  const [selected, setSelected] = useState(offerType);
  const [brainType, setBrainType] = useState(shopBrainType);

  const handleChange = useCallback((value) => {
    setSelected(value[0]);
    changeOfferType(null, value[0]);
  }, []);

  const handleShopBrainTypeChange = useCallback((value) => {
    setBrainType(value[0]);
    changeOfferType(null, 'ai', value[0]);
  }, []);

  return (
    <div className='Offer-type_wrapper'>
      <div>
        <Text variant="headingSm" as="h3">Product selection</Text>
        <ChoiceList
          title="Product selection<"
          titleHidden
          choices={[
            {
              label: 'Manual',
              value: 'manual',
              helpText: 'Manually choose the products to show as an upsell',
            },
            {
              label: 'ShopBrain',
              value: 'ai',
              helpText: (
                <div>
                  <div>Let our algorithm show the best product recommendations</div>
                  {selected === 'ai' && (
                    <div style={{ marginTop: '10px' }}>
                      <ChoiceList
                      choices={[
                        {
                          label: 'Complementary products',
                          value: 'complementary',
                        },
                        {
                          label: 'Related products',
                          value: 'related',
                        },
                      ]}
                      selected={[brainType]}
                      onChange={handleShopBrainTypeChange}
                      horizontal
                    />
                    </div>
                  )}
                </div>
              )
            },
            {
              label: 'Autopilot',
              value: 'autopilot',
              helpText: 'Show random items from a collection or with a certain tag',
            },
          ]}
          selected={selected}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default OfferTypeCheckbox;